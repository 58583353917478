import React from "react";
import Slider from "react-slick";
import { Container, Card } from "react-bootstrap";
import Image from "components/image";
import {
  title, description, heroes,
  hero, heroCard, heroBody,
  heroImage, heroContent, heroTitle,
  heroSubtitle, shadow
} from "./style.mod.scss";
import Link from "components/link";

const Heroes = ({ data }) => {
  const settings = {
    infinite: false,
    autoplay: false,
    arrows: false,
    dots: true,
    centerPadding: "60px 20px  20px 60px",
    slidesToShow:1,
    className: "center",
    centerMode: true,
    slidesToScroll: 1,
    rows: 2,
    slidesPerRow: 3,
    responsive: [{
      breakpoint: 767,
      settings: {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "40px",
        slidesToShow: 1,
        slidesToScroll:1,
        initialSlide: 1,
        rows: 1,
        slidesPerRow: 1,
        // centerMode: true,
        // // autoplay: true,
        // className: "center",
        // slidesToShow: 1,

        // // centerPadding: "60px",
        // rows: 1,
        // slidesPerRow: 1,
        // slidesToScroll:1,
        // initialSlide: 1


        // arrows: false,
        // centerMode: true,
        // slidesToShow: 1,
        // className: "center",
        // centerPadding: "80px",
        // rows: 1,
        // slidesPerRow: 1,
        // slidesToScroll: 1,
        // initialSlide: 3
      }
    }]
  };

  return (
    <div className={heroes}>
      <Container>
        <h2 className={title}>{data.title}</h2>
        <p className={description}>{data.subtitle}</p>
        <Slider {...settings}>
          {
            data.Heroes.map((heroData) => (
              <div className={hero} key={heroData._key}>
                <Link to={heroData?.link}>
                  <Card className={heroCard}>
                    <Card.Body className={heroBody}>
                      <Image
                        className={heroImage}
                        image={heroData.mainimage}
                        hoverImage={heroData.hoverimage}
                      />
                      <Card.Text className={heroContent}>
                        <p className={heroTitle}>{heroData.name}</p>
                        <p className={heroSubtitle}>{heroData?.position}</p>
                        <p className={heroSubtitle}>{heroData?.bio}</p>
                      </Card.Text>
                      <div className={shadow}></div>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            ))
          }
        </Slider>
      </Container>
    </div>
  );
};

export default Heroes;
