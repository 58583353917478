// extracted by mini-css-extract-plugin
export var center = "style-mod--center--2OesK";
export var heroes = "style-mod--heroes--3_rXj";
export var title = "style-mod--title--1ibfN";
export var description = "style-mod--description--p7JBI";
export var hero = "style-mod--hero--ihSXN";
export var shadow = "style-mod--shadow--1li50";
export var heroCard = "style-mod--hero-card--2yHrL";
export var heroBody = "style-mod--hero-body--1etMP";
export var heroImage = "style-mod--hero-image--21XRL";
export var heroContent = "style-mod--hero-content--1E1lQ";
export var heroTitle = "style-mod--hero-title--1TgFv";
export var heroSubtitle = "style-mod--hero-subtitle--1zQ64";